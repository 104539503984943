import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import Hotspot from "../components/Hotspot";

export default function Scene12Mesh({setScene, setRender, setHotspot}) {
    return (
        <>
            <Hotspot position={[50, -10, -2]} setOpen={setHotspot} hotspot={6} image="/images/assets/hotspot/hotspot-vasca-acqua.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={11} setRender={setRender} position={[10, -35, -90]} rotation={'rotateZ(0deg) rotateY(-10deg) rotateX(-50deg'}/>
        </>
    )
}
