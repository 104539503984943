import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import Hotspot from "../components/Hotspot";

export default function Scene11Mesh({setScene, setRender, setHotspot}) {
    return (
        <>
            <Hotspot position={[-45, -10, 20]} setOpen={setHotspot} hotspot={8} image="/images/assets/hotspot/hotspot-rooftop-bar.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={12} setRender={setRender} position={[-45, -35, 70]} rotation={'rotateZ(0deg) rotateY(-10deg) rotateX(-50deg'}/>
        </>
    )
}
