import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import Hotspot from "../components/Hotspot";

export default function Scene7Mesh({setScene, setRender, setHotspot}) {
    return (
        <>
            <Hotspot position={[100, -5, -25]} setOpen={setHotspot} hotspot={4} image="/images/assets/hotspot/domotica-multimedia.png"/>
            <Hotspot position={[-55, 5, -550]} setOpen={setHotspot} hotspot={3} image="/images/assets/hotspot/hotspot-servizi-igienici.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={8} setRender={setRender} position={[-5, -35, -80]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-50deg'}/>
            <NavigationArrow setScene={setScene} sceneToSet={2} setRender={setRender} position={[20, -35, 100]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-50deg'}/>
            <NavigationArrow setScene={setScene} sceneToSet={11} setRender={setRender} position={[75, -20, 20]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(0deg'} classes={'stairs-arrow'}/>
        </>
    )
}
