import React from 'react'
import {Html} from "@react-three/drei";

export default function ChangeAllestimento({setScene, sceneToSet, position, rotation, classes}) {
    return (
        <mesh position={position}>
            <boxGeometry attach="geometry" args={[0, 0, 0]} />
            <meshBasicMaterial/>
            <Html center>
                <div className="w-[150px] h-[150px]" style={{transform: rotation}}>
                    <img alt="change-expo" src="/images/assets/hotspot/hotspot-9bis.png" className={`cursor-pointer ${classes}`} onClick={() => setScene(sceneToSet)}/>
                </div>
            </Html>
        </mesh>
    )
}
