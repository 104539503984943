import * as THREE from 'three'
import React, {Suspense, useRef, useState} from 'react'
import {Canvas, extend, useFrame, useLoader, useThree} from 'react-three-fiber'
import NavigationArrow from "../components/NavigationArrow";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import MainMenu from "../components/MainMenu";
import Hotspot from "../components/Hotspot";
import {Slide} from "react-slideshow-image";

extend({OrbitControls})

function Controls(props) {
    const {camera, gl} = useThree()
    const ref = useRef()
    useFrame(() => ref.current.update())
    return <orbitControls ref={ref} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]}/>
}

function Scene() {
    const texture = useLoader(THREE.TextureLoader, '/images/panoramas/panorama-2.jpg')
    return (
        <mesh receiveShadow>
            <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
            <meshBasicMaterial attach="material" map={texture} side={THREE.BackSide}/>
        </mesh>
    )
}

const slideImages = [
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-ingresso.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-show-cooking.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-rooftop.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-rooftop1.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-allestimento.jpg',
    }
];

const Slideshow = () => {
    const properties = {
        duration: 3000,
        autoplay: true,
        transitionDuration: 1000,
        arrows: false,
        infinite: true,
        easing: "ease",
    };
    return (
        <div className="slide-container">
            <Slide {...properties}>
                {slideImages.map((slideImage, index)=> (
                    <div className="each-slide" key={index}>
                        <div className="bg-cover bg-no-repeat bg-center h-screen w-full p-10 flex flex-col justify-between" style={{backgroundImage: `url(${slideImage.url})`}}>
                            <span />
                            <a href="https://spazioillumi.it/" rel="nofollow" target="_blank" className="font-primary text-white font-bold self-end">spazioilumi.it</a>
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default function Scene2({setScene}) {
    const [hotspotOpen, setHotspotOpen] = useState(0)
    return (
        <>
            <Canvas camera={{fov: 90, position: [0, 0, 0.1]}} className="z-30">
                <Controls enableZoom={false} enablePan={false} enableDamping dampingFactor={0.1} rotateSpeed={-0.2}/>
                <Suspense fallback={null}>
                    <Hotspot position={[-70, -35, -15]} setOpen={setHotspotOpen} hotspot={1}/>
                    <NavigationArrow setScene={setScene} sceneToSet={1} position={[20, -25, -3]} rotation={'rotateZ(5deg) rotateY(5deg) rotateX(-40deg'}/>
                    <NavigationArrow setScene={setScene} sceneToSet={7} position={[15, -35, -100]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-70deg'}/>
                    <NavigationArrow setScene={setScene} sceneToSet={3} position={[27, -35, 100]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-70deg'}/>
                    <Scene/>
                </Suspense>
            </Canvas>
            <MainMenu/>
            <div className="p-[30px] absolute top-0 left-0">
                <img src="/images/Illumi-logo-white.png" className="h-[83px] w-[100%]"/>
            </div>
            <div className="absolute bottom-0 flex flex-row gap-[50px] pb-[30px]" style={{width: 200, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center'}}>
                <button className="flex flex-col items-center w-[200px]" onClick={() => setScene(11)}>
                    <img src="/images/assets/arrow-up.svg" className="w-[50px] h-[50px] mb-2"/>
                    <div>
                        <span className="font-primary font-extrabold text-[27px] text-white" style={{textShadow: "1px 1px #000000"}}>vai al rooftop</span>
                    </div>
                </button>
            </div>
            <a href="https://spazioillumi.it/" rel="nofollow" target="_blank" className="absolute bottom-0 right-0 font-primary text-white font-bold self-end p-[30px]">spazioilumi.it</a>

            {hotspotOpen === 1 &&
            <div className="bg-[#bd7762]/80 h-screen w-screen absolute top-0 left-0 z-40 flex flex-col items-center justify-center">
                <div className="bg-[#f2efef] w-[80vw] h-[80vh] flex flex-row">
                    <div className="h-full w-full overflow-hidden">
                        <Slideshow />
                    </div>
                    <div className="w-[55%] min-w-[55%] overflow-hidden flex flex-col">
                        <div className="self-end p-[30px] pb-[10px]">
                            <img src="/images/assets/close-menu-icon.svg" className="h-[70] w-[70px] cursor-pointer" onClick={() => setHotspotOpen(0)}/>
                        </div>
                        <div className="p-[60px] pt-[0] overflow-hidden">
                            <h2 className="font-primary text-[45px] leading-[54px] font-semibold pb-10">Lorem ipsum</h2>
                            <h3 className="font-primary text-[20px] leading-[30px] font-bold pb-2">La Domotica</h3>
                            <p className="font-primary text-[13px] leading-[21px] pb-[30px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum leo est, quis rhoncus orci maximus porta. Mauris maximus ante eu magna mollis, eget vulputate velit ornare. Donec egestas odio et ligula venenatis maximus. Nam finibus eleifend mauris, sit amet finibus turpis commodo ac. Pellentesque aliquam iaculis dolor, quis efficitur magna cursus sed.</p>
                            <h3 className="font-primary text-[20px] leading-[30px] font-bold pb-2">La Climatizzazione</h3>
                            <p className="font-primary text-[13px] leading-[21px] pb-[30px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum leo est, quis rhoncus orci maximus porta. Mauris maximus ante eu magna mollis, eget vulputate velit ornare. Donec egestas odio et ligula venenatis maximus. Nam finibus eleifend mauris, sit amet finibus turpis commodo ac. Pellentesque aliquam iaculis dolor, quis efficitur magna cursus sed.</p>
                            <h3 className="font-primary text-[20px] leading-[30px] font-bold pb-2">L&apos;Illuminazione</h3>
                            <p className="font-primary text-[13px] leading-[21px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum leo est, quis rhoncus orci maximus porta. Mauris maximus ante eu magna mollis, eget vulputate velit ornare. Donec egestas odio et ligula venenatis maximus. Nam finibus eleifend mauris, sit amet finibus turpis commodo ac. Pellentesque aliquam iaculis dolor, quis efficitur magna cursus sed.</p>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
