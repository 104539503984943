import ReactDOM from 'react-dom'
import React, {Suspense, useEffect, useMemo, useState} from 'react'
import {Canvas, useFrame, useLoader, useThree} from "react-three-fiber";
import {Html, OrbitControls, Preload, useProgress} from "@react-three/drei";
import * as THREE from "three";
import Scene1Content from "./contents/Scene1Content";
import DayNightContent from "./contents/DayNightContent";
import Scene2Content from "./contents/Scene2Content";
import Scene3Content from "./contents/Scene3Content";
import Scene4Content from "./contents/Scene4Content";
import Scene5Content from "./contents/Scene5Content";
import Scene6Content from "./contents/Scene6Content";
import Scene7Content from "./contents/Scene7Content";
import Scene8Content from "./contents/Scene8Content";
import Scene9Content from "./contents/Scene9Content";
import Scene10Content from "./contents/Scene10Content";
import Scene11Content from "./contents/Scene11Content";
import Scene12Content from "./contents/Scene12Content";
import Scene1Mesh from "./mesh/Scene1Mesh";
import Scene2Mesh from "./mesh/Scene2Mesh";
import Scene3Mesh from "./mesh/Scene3Mesh";
import Scene4Mesh from "./mesh/Scene4Mesh";
import Scene5Mesh from "./mesh/Scene5Mesh";
import Scene6Mesh from "./mesh/Scene6Mesh";
import Scene7Mesh from "./mesh/Scene7Mesh";
import Scene8Mesh from "./mesh/Scene8Mesh";
import Scene9Mesh from "./mesh/Scene9Mesh";
import Scene10Mesh from "./mesh/Scene10Mesh";
import Scene11Mesh from "./mesh/Scene11Mesh";
import Scene12Mesh from "./mesh/Scene12Mesh";
import DayNight from "./scenes/DayNight";
import {Box, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {lerp} from "three/src/math/MathUtils";
import {isMobile} from "react-device-detect";

export default function VirtualTour2() {

    const [render, setRender] = useState(0)
    const [update, setUpdate] = useState(0)
    const [transition, setTransition] = useState(false)
    function Foo({store, which}) {
        const camera = useThree((state) => state.camera)
        const controls = useThree((state) => state.controls)
        const positionArray = store[which].position
        const [x, y, z] = positionArray
        if(render === 0) {
            camera.position.set(x, y, z);
        }
        // Get current camera position to simply set the view
        /*useEffect(() => {
            console.log(camera.position.x, ', ', camera.position.y, ', ', camera.position.z)
        }, [update])*/
    }

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex'}}>
                <CircularProgress variant="determinate" value={Math.round(props.value)} size={75} sx={{color: "#ffffff"}} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="#ffffff" fontSize={15} fontWeight={700}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    function Loader() {
        const { progress } = useProgress()
        return <Html center>
            <div className="h-[100vh] w-[100vw] flex flex-col justify-center items-center" style={{backgroundColor: "rgb(189 119 98 / 0.8)"}}>
                <CircularProgressWithLabel value={progress} />
            </div>
        </Html>
    }

    const store = [
        {name: 'day-night', Mesh: Scene1Mesh, url: '/images/panoramas/panorama-1.jpg', link: 0, position: [0.5, 0.1, 0.01], rotation: [0, 0, 0]},
        {name: 'atrio', Mesh: Scene1Mesh, url: '/images/panoramas/panorama-1.jpg', link: 1, position: [-0.04737905149735895, 0.01415663250594768, 0.08691844013385343], rotation: [0, 0, - Math.PI * 3 / 180]},
        {name: 'ingresso', Mesh: Scene2Mesh, url: '/images/panoramas/panorama-2.jpg', link: 2, position: [-0.07347595600874893 ,  0.005866272580502669 ,  -0.06757862631492147], rotation: [0, 0, 0]},
        {name: 'cucina-1', Mesh: Scene3Mesh, url: '/images/panoramas/panorama-3.jpg', link: 3, position: [0.03312002163028324,  0.022457004810757478,  0.09164467852602995], rotation: [0, 0, 0]},
        {name: 'cucina-2', Mesh: Scene4Mesh, url: '/images/panoramas/panorama-4.jpg', link: 4, position: [-0.09670757015593787,  0.010601311735560885,  -0.02313564488014135], rotation: [0, 0, Math.PI * 3 / 180]},
        {name: 'balcone-cucina', Mesh: Scene5Mesh, url: '/images/panoramas/panorama-5.jpg', link: 5, position: [0.0959563593590129,  0.017819713821484204,  0.021790706686209107], rotation: [ - Math.PI * 5 / 180, 0, 0]},
        {name: 'balcone-cucina-3', Mesh: Scene6Mesh, url: '/images/panoramas/panorama-6.jpg', link: 6, position: [-0.09594291671099814 ,  0.027659396268458435 ,  0.005469417798166028], rotation: [0, 0, 0]},
        {name: 'salone', Mesh: Scene7Mesh, url: '/images/panoramas/panorama-7.jpg', link: 7, position: [-0.047050666784170705 ,  0.010219496596742338 ,  0.08764585925458351], rotation: [0, 0, 0]},
        {name: 'sala-riunioni', Mesh: Scene8Mesh, url: '/images/panoramas/panorama-8.jpg', link: 8, position: [-0.004463267144579486 ,  0.0079256399513163 ,  -0.09958545816412287], rotation: [0, 0, Math.PI * 3 / 180]},
        {name: 'allestimento-1', Mesh: Scene9Mesh, url: '/images/panoramas/panorama-9.jpg', link: 9, position: [-0.09859476979218702 ,  0.01668528628207771 ,  -0.0008201166446003567], rotation: [0, 0, 0]},
        {name: 'allestimento-2', Mesh: Scene10Mesh, url: '/images/panoramas/panorama-10.jpg', link: 10, position: [-0.09859476979218702 ,  0.01668528628207771 ,  -0.0008201166446003567], rotation: [0, 0, 0]},
        {name: 'rooftop-1', Mesh: Scene11Mesh, url: '/images/panoramas/panorama-11.jpg', link: 11, position: [0, 0, 0.01], rotation: [0, 0, Math.PI * 2 / 180]},
        {name: 'rooftop-2', Mesh: Scene12Mesh, url: '/images/panoramas/panorama-12.jpg', link: 12, position: [0, 0, 0.01], rotation: [0, 0, Math.PI * 2 / 180]}
    ]

    const meshMat = React.useRef()
    const [hotspot, setHotspot] = useState(0)
    const [which, setCurrent] = useState(0)
    function set(id) {
        if(which !== 0) {
            setTransition(true)
            setTimeout(function(){ setTransition(false) }, 500)
        }
        setTimeout(function(){ setCurrent(id) }, 500)
    }

    function Dome({texture, set, Mesh, link, rotation}) {
        return (
            <group>
                <mesh rotation={rotation}>
                    <sphereBufferGeometry args={[500, 60, 40]}/>
                    <meshBasicMaterial ref={meshMat} attach="material" map={texture} side={THREE.BackSide} transparent={true}/>
                </mesh>
                {<Mesh setScene={set} setHotspot={setHotspot} setRender={setRender}/>}
            </group>
        )
    }

    function Portals() {
        const {link, ...props} = store[which]
        const maps = useLoader(THREE.TextureLoader, store.map((entry) => entry.url))
        return <Dome onClick={() => set(link)} {...props} texture={maps[which]} set={set} link={link}/>
    }

    const [isAutoRotate, setIsAutoRotate] = useState(true)
    const toggleAutoRotate = () => {
        setRender(old => old+1)
        setIsAutoRotate(!isAutoRotate)
    }

    function displayContent() {
        switch (which) {
            case 0:
                return <DayNightContent setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            case 1:
                return <Scene1Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            case 2:
                return <Scene2Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
            case 3:
                return <Scene3Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
            case 4:
                return <Scene4Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
            case 5:
                return <Scene5Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            case 6:
                return <Scene6Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            case 7:
                return <Scene7Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
            case 8:
                return <Scene8Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            case 9:
                return <Scene9Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
            case 10:
                return <Scene10Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            case 11:
                return <Scene11Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
            case 12:
                return <Scene12Content setScene={set} toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate} setHotspot={setHotspot} hotspot={hotspot}/>
        }
    }

    const SceneTransition = () => {
        if(transition) {
            return (
                <Html center>
                    <div className="h-[100vh] w-[100vw] flex flex-col justify-center items-center" style={{backgroundColor: "rgb(189 119 98 / 0.8)"}}>
                        <div className="lds-ripple">
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </Html>
            )
        } else {
            return
        }
    }
    return (
        <>
            {which !== 0 &&
            <>
                <Canvas className="z-30" style={{overflowY: "scroll"}} camera={{position: [0, 0, 0.1], rotation: [2, 1, 1]}} legacy={false} flat={true}>
                    <Foo store={store} which={which} />
                    <OrbitControls enableZoom={false} enablePan={false} autoRotate={isAutoRotate} autoRotateSpeed={0.3} enableDamping dampingFactor={0.1} rotateSpeed={-0.2}/>
                    <Suspense fallback={<Loader/>}>
                        <Preload all/>
                        <SceneTransition/>
                        <Portals/>
                    </Suspense>
                </Canvas>
                <div className="z-40">
                    {displayContent()}
                </div>
            </>}
            {which === 0 && <DayNight setScene={set}/>}
        </>
    )
}

ReactDOM.render(<VirtualTour2/>, document.getElementById('root'))
