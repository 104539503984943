import React from 'react'
import NavigationArrow from "../components/NavigationArrow";

export default function Scene5Mesh({setScene, setRender}) {
    return (
        <>
            <NavigationArrow setScene={setScene} sceneToSet={4} setRender={setRender} position={[50, -35, -20]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-30deg'} classes="external-nav-arrow"/>
        </>
    )
}
