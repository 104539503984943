import React from 'react'
import {isMobile, useMobileOrientation} from "react-device-detect";
import {Slide} from "react-slideshow-image";

const slideImages = [
    {
        url: '/images/popups/sanificazione/sanificazione.jpg',
    }
];

const Slideshow = () => {
    const properties = {
        duration: 4000,
        autoplay: false,
        transitionDuration: 1000,
        arrows: false,
        infinite: false,
        easing: "ease",
        prevArrow: <button style={{marginLeft: 20}} ><img alt="prev-slide" src="/images/assets/slider-arrow-prev.png" className="cursor-pointer" /></button>,
        nextArrow: <button style={{marginRight: 20}}><img alt="prev-slide" src="/images/assets/slider-arrow-next.png" className="cursor-pointer" /></button>,
    };
    return (
        <div className="slide-container h-full">
            <Slide {...properties}>
                {slideImages.map((slideImage, index) => (
                    <div className="each-slide h-full" key={index}>
                        <div className="bg-cover bg-no-repeat h-full w-full" style={{backgroundImage: `url(${slideImage.url})`}}>
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default function SanificazioneHotspot({setHotspot}) {
    const { isLandscape } = useMobileOrientation()
    return (
        <div className="bg-[#bd7762]/80 h-screen w-screen absolute top-0 left-0 z-40 flex flex-col items-center justify-center">
            <div className={`overflow-hidden bg-[#f2efef] flex ${isMobile && !isLandscape ? "flex-col" : isMobile && isLandscape} ${isMobile ? "w-[90vw] h-[90vh]" : "flex-row w-[80vw] h-[80vh]"}`}>
                <div className={`w-full overflow-hidden ${isMobile && !isLandscape ? "h-[400px]" :  "h-full"} ${isMobile && isLandscape ? "min-w-[300px]" : ""}`}>
                    <Slideshow />
                </div>
                <div className={`overflow-hidden flex flex-col ${isMobile ? "h-full" : "w-[55%] min-w-[55%]"}`}>
                    <div className={`p-[30px] pb-[10px] ${isMobile && !isLandscape ? "absolute top-[30px] right-[10px] p-[20px]" : isMobile && isLandscape ? "absolute top-[10px] right-[30px] p-[20px]" : "self-end"}`}>
                        <img alt="close-icon" src="/images/assets/close-menu-icon.svg" className="h-[45px] w-[45px] cursor-pointer" onClick={() => setHotspot(0)}/>
                    </div>
                    <div className={`overflow-hidden ${isMobile ? "p-[30px] pt-[40px]" : "p-[60px] pt-[0]"}`} style={{overflowY: 'auto'}}>
                        <h2 className="font-primary text-[38px] leading-[54px] font-semibold pb-10">Sanificazione</h2>
                        <p className="font-primary text-[13px] leading-[21px] pb-[30px]">Sistema di sanificazione continua dust free che agisce in maniera attiva 24/24 generando ioni ossidanti naturali i quali trasportati dal flusso d’aria distruggono virus ed agenti inquinanti che incontrano sia nei canali che in ambiente.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
