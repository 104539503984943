import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import Hotspot from "../components/Hotspot";

export default function Scene2Mesh({setScene, setHotspot, setRender}) {
    return (
        <>
            <Hotspot position={[35, 5, 60]} setOpen={setHotspot} hotspot={1} image="/images/assets/hotspot/hotspot-green.png"/>
            <Hotspot position={[-35, -15, 40]} setOpen={setHotspot} hotspot={2} image="/images/assets/hotspot/hotspot-arredi.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={7} setRender={setRender} position={[15, -35, -100]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-50deg'}/>
            <NavigationArrow setScene={setScene} sceneToSet={3} setRender={setRender} position={[27, -35, 100]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-50deg'}/>
        </>
    )
}
