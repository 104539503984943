import React from 'react'
import NavigationArrow from "../components/NavigationArrow";

export default function Scene1Mesh({setScene, setRender}) {
    return (
        <>
            <NavigationArrow setScene={setScene} sceneToSet={2} setRender={setRender} position={[75, -20, -105]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(0deg'} classes={'stairs-arrow'}/>
        </>
    )
}
