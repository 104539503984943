import React, {useState} from 'react'
import {Slider} from "@mui/material";
import { motion } from "framer-motion"
import {isMobile, useMobileOrientation} from "react-device-detect";
import '../../App.css'

const audio = new Audio('/38015275_chill-ambient_by_stock_music_preview.mp3');
audio.loop = true
audio.volume = 0.3

export default function MainMenu({toggleAutoRotate, isAutoRotate, which}) {
    const { isLandscape } = useMobileOrientation()
    const menuIconSize = isMobile ? 45 : 70

    const [isOpen, setIsOpen] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [isAudioActive, setIsAudioActive] = useState(!audio.paused)
    const [audioVolume, setAudioVolume] = useState(audio.volume)

    // Audio track
    function play() {
        if (audio) {
            audio.volume = 0.3
            audio.play();
            setIsAudioActive(true)
        }
    }

    function preventHorizontalKeyboardNavigation(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }

    // Full screen mode
    function openFullscreen() {
        let elem = document.getElementById("root");
        if (isFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozExitFullScreen) { /* Firefox */
                document.mozExitFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
            setIsFullScreen(false)
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                elem.webkitRequestFullscreen(elem.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
            setIsFullScreen(true)
            elem.style.width = '100%';
            elem.style.height = '100%';
        }
    }

    // Auto rotation
    function toggleRotation() {
        toggleAutoRotate()
    }

    const variants = {
        open: {
            transition: { staggerChildren: 0.07, delayChildren: 0.2 }
        },
        closed: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        }
    };
    const variantsItem = {
        open: {
            x: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
            }
        },
        closed: {
            x: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 }
            }
        }
    };

    return (
        <div className={`p-[10px] lg:p-[30px] absolute top-0 right-0 z-40 text-right flex flex-col items-end ${isMobile && isOpen ? "bg-white/75 w-full" : ""}`} style={{pointerEvents: "none"}}>
            <div className="flex flex-row gap-[20px] w-max" style={{pointerEvents: "none"}}>
                {!isMobile && <div className="flex flex-row gap-[30px]" style={{display: isOpen ? "block" : "none"}}>
                    <motion.div className="flex flex-row gap-[30px] items-center h-max" variants={variants} style={{pointerEvents: "none"}}>
                        <a href="https://www.google.com/maps/place/Spazio+Illumi/@45.4623706,9.1883148,15z/data=!4m5!3m4!1s0x0:0x9df065a8c35243d2!8m2!3d45.4623706!4d9.1883148" target="_blank" rel="nofollow"><motion.img src="/images/assets/menu-position-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false} style={{pointerEvents: "all"}}/></a>
                        <motion.div className="w-[30px] h-[2px] bg-white" variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false}/>
                        <a href="https://www.instagram.com/spazioillumi/" target="_blank" rel="nofollow"><motion.img src="/images/assets/menu-instagram-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false} style={{pointerEvents: "all"}}/></a>
                        <a href="https://www.linkedin.com/company/spazio-illumi/" target="_blank" rel="nofollow"><motion.img src="/images/assets/menu-linkedin-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false} style={{pointerEvents: "all"}}/></a>
                        <motion.div className="w-[30px] h-[2px] bg-white" variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false}/>
                        <a href="https://wa.me/3404991375" target="_blank" rel="nofollow"><motion.img src="/images/assets/menu-whatsapp-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false} style={{pointerEvents: "all"}}/></a>
                        <a href="tel:0209956546" target="_blank" rel="nofollow"><motion.img src="/images/assets/menu-phone-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false} style={{pointerEvents: "all"}}/></a>
                        <a href="mailto:chiedimi@spazioillumi.it" target="_blank" rel="nofollow"><motion.img src="/images/assets/menu-mail-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false} style={{pointerEvents: "all"}}/></a>
                    </motion.div>
                </div>}
                {isMobile && <div className="flex flex-row gap-[10px] z-40" style={{display: isOpen ? "block" : "none"}}>
                    <motion.div className="flex flex-row gap-[15px] items-start h-max" variants={variants}>
                        <motion.div className="flex flex-col gap-[10px]" variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false}>
                            <a href="https://www.google.com/maps/place/Spazio+Illumi/@45.4623706,9.1883148,15z/data=!4m5!3m4!1s0x0:0x9df065a8c35243d2!8m2!3d45.4623706!4d9.1883148" target="_blank" rel="nofollow"><img src="/images/assets/menu-position-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/></a>
                        </motion.div>
                        <div className="h-[50px] w-[2px] bg-white" variants={variantsItem}/>
                        <motion.div className="flex flex-col gap-[10px]" variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false}>
                            <a href="https://www.instagram.com/spazioillumi/" target="_blank" rel="nofollow"><img src="/images/assets/menu-instagram-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/></a>
                            <a href="https://www.linkedin.com/company/spazio-illumi/" target="_blank" rel="nofollow"><img src="/images/assets/menu-linkedin-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/></a>
                        </motion.div>
                        <div className="h-[120px] w-[2px] bg-white"/>
                        <motion.div className="flex flex-col gap-[10px]" variants={variantsItem} animate={isOpen ? "open" : "closed"} initial={false}>
                            <a href="https://wa.me/3404991375" target="_blank" rel="nofollow"><img src="/images/assets/menu-whatsapp-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/></a>
                            <a href="tel:0209956546" target="_blank" rel="nofollow"><img src="/images/assets/menu-phone-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/></a>
                            <a href="mailto:chiedimi@spazioillumi.it" target="_blank" rel="nofollow"><img src="/images/assets/menu-mail-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/></a>
                        </motion.div>
                        <div className="h-[190px] w-[2px] bg-white"/>
                    </motion.div>
                </div>}
                <div className="flex flex-col gap-[10px] lg:gap-[30px] items-center" style={{pointerEvents: "all"}}>
                    {!isOpen && <img src="/images/assets/menu-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} onClick={() => setIsOpen(!isOpen)} style={{pointerEvents: "all"}}/>}
                    {isOpen && <img src="/images/assets/close-menu-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} onClick={() => setIsOpen(!isOpen)} style={{pointerEvents: "all"}}/>}
                    <img src="/images/assets/fullscreen-menu-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} onClick={() => openFullscreen()} style={{pointerEvents: "all"}}/>
                    {which !== 0 && <img src={`${isAutoRotate ? "/images/assets/ico-round.svg" : "/images/assets/ico-round-stop.svg"}`} className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} onClick={() => toggleRotation()} style={{pointerEvents: "all"}}/>}
                    {isAudioActive && <img src="/images/assets/audio-menu-icon.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} style={{pointerEvents: "all"}}/>}
                    {!isAudioActive && <img src="/images/assets/audio-menu-icon-mute.svg" className={`h-[${menuIconSize}px] w-[${menuIconSize}px] cursor-pointer`} onClick={() => play()} style={{pointerEvents: "all"}}/>}
                    {isAudioActive && <div className={`h-[100px] mt-[10px] ${isMobile && isLandscape ? "absolute bottom-[10px] left-[-30px]" : ""}`} style={{pointerEvents: "all"}}>
                        <Slider
                            sx={{
                                '& input[type="range"]': {
                                    WebkitAppearance: 'slider-vertical',
                                },
                                '&.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.MuiSlider-vertical': {
                                    width: 8,
                                    color: '#ffffff'
                                },
                                '& .MuiSlider-track': {
                                    border: '2px solid rgb(163, 102, 84)'
                                },
                                '& .MuiSlider-thumbColorPrimary': {
                                    color: '#a36654',
                                    border: '2px solid #ffffff',
                                    width: 25,
                                    height: 25,
                                },
                                '& .MuiSlider-thumb': {
                                    '&:hover': {
                                        boxShadow: '0px 0px 0px 8px rgba(163, 102, 84, 0.16) !important'
                                    }
                                }
                            }}
                            min={0}
                            max={100}
                            step={10}
                            orientation={"vertical"}
                            defaultValue={30}
                            value={audioVolume !== 0 ? audioVolume * 100 : 30}
                            aria-label="Volume"
                            onChange={(e) => {
                                if(audio.volume === 0) {
                                    audio.pause()
                                    setIsAudioActive(false)
                                } else {
                                    audio.volume = e.target.value / 100
                                    setAudioVolume(e.target.value / 100)
                                }
                            }}
                            onKeyDown={preventHorizontalKeyboardNavigation}
                        />
                    </div>}
                </div>
            </div>
        </div>
    )
}
