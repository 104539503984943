import React from 'react'
import NavigationArrow from "../components/NavigationArrow";

export default function Scene8Mesh({setScene, setRender}) {
    return (
        <>
            <NavigationArrow setScene={setScene} sceneToSet={9} setRender={setRender} position={[40, -35, 40]} rotation={'rotateZ(0deg) rotateY(10deg) rotateX(-30deg'}/>
            <NavigationArrow setScene={setScene} sceneToSet={7} setRender={setRender} position={[-90, -35, 0]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-50deg'}/>
        </>
    )
}
