import React from 'react'
import MainMenu from "../components/MainMenu";
import {isMobile, useMobileOrientation} from "react-device-detect";
import VascaHotspot from "../hotspot-content/Vasca.hotspot";

export default function Scene12Content({setScene, toggleAutoRotate, isAutoRotate, hotspot, setHotspot}) {
    const { isLandscape } = useMobileOrientation()
    return (
        <>
            <MainMenu toggleAutoRotate={toggleAutoRotate} isAutoRotate={isAutoRotate}/>
            <div className="p-[10px] lg:p-[30px] absolute top-0 left-0 z-30">
                <img alt="illumi-logo" src="/images/Illumi-logo-white.png" className={`${isMobile ? "h-[50px] w-[100%] max-w-[157px]" : "h-[83px] w-[100%]"}`}/>
            </div>
            <div className="absolute bottom-0 flex flex-row gap-[50px] pb-[10px] lg:pb-[30px] z-40" style={{width: isMobile && isLandscape ? 140 : 200, marginLeft: isMobile && isLandscape ? 0 : 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center', marginBottom: isMobile && !isLandscape ? '40px' : 0}}>
                <button className="flex flex-col items-center w-[200px]" onClick={() => setScene(2)}>
                    <img alt="arrow-down-icon" src="/images/assets/arrow-down.svg" className="w-[45px] h-[45px] lg:w-[50px] lg:h-[50px] mb-2" style={{display: isMobile ? "none" : "block"}}/>
                    <div>
                        <span className={`font-primary font-extrabold text-white ${isMobile ? 'text-[16px]' : 'text-[27px]'}`} style={{textShadow: "1px 1px #000000"}}>vai all'attico</span>
                    </div>
                </button>
            </div>
            <a href="https://spazioillumi.it/" rel="noreferrer" target="_blank" className={`absolute bottom-0 right-0 font-primary text-white font-bold self-end m-[10px] lg:m-[30px] z-40 ${isMobile && !isLandscape ? "ml-[auto] mr-[auto] text-center left-0" : ""}`}>spazioillumi.it</a>
            {hotspot === 6 &&
            <VascaHotspot setHotspot={setHotspot} />
            }
        </>
    )
}
