import * as THREE from 'three'
import React, {Suspense, useEffect, useRef, useState} from 'react'
import {Canvas, extend, useFrame, useLoader, useThree} from 'react-three-fiber'
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import MainMenu from "../components/MainMenu";
import {isMobile, useMobileOrientation} from "react-device-detect";
import {Box, CircularProgress, Typography} from "@mui/material";
import {Html, useProgress} from "@react-three/drei";
import Hotspot from "../components/Hotspot";

extend({OrbitControls})

function Controls(props) {
    const {camera, gl} = useThree()
    const ref = useRef()
    useFrame(() => ref.current.update())
    return <orbitControls ref={ref} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]}/>
}

function Scene() {

    const video = document.getElementById('day-night-video');
    video.oncanplaythrough = function() {
        video.muted = true;
        video.play();
    }

    const material1 = useRef()
    const material2 = useRef()
    const material3 = useRef()
    const material3bis = useRef()

    const texture1 = useLoader(THREE.TextureLoader, '/images/panoramas/day-night/daynight3.jpg')
    const texture2 = useLoader(THREE.TextureLoader, '/images/panoramas/day-night/daynight2.jpg')
    const texture3 = useLoader(THREE.TextureLoader, '/images/panoramas/day-night/daynight1.jpg')

    function delay(material, i, time) {
        setTimeout(() => {
            material.current.opacity = i/100
        }, time);
    }
    function changeOpacity(material, transitionTime) {
        let i = 99
        let time = transitionTime
        do {
            delay(material, i, time)
            i = i - 1
            time = time + transitionTime
        } while (i > -1)

    }

    useEffect(() => {
        let fadingTime = 50
        setTimeout(function(){
            changeOpacity(material3, fadingTime)
        }, 2000);
        setTimeout(function(){
            changeOpacity(material2, fadingTime)
        }, 4000 + 5000);
        setTimeout(function(){
            changeOpacity(material1, fadingTime)
        }, 11000 + 5000);
        setTimeout(function(){
            setInterval(() => {
                material3.current.opacity = 1
                material2.current.opacity = 1
                material1.current.opacity = 1
                setTimeout(function(){
                    changeOpacity(material3, fadingTime)
                }, 2000);
                setTimeout(function(){
                    changeOpacity(material2, fadingTime)
                }, 9000);
                setTimeout(function(){
                    changeOpacity(material1, fadingTime)
                }, 16000);
            }, 23000)
        }, 2000);
    }, []);


    return (
        <>
            <mesh>
                <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
                <meshBasicMaterial ref={material3bis} attach="material" map={texture3} side={THREE.BackSide} transparent/>
            </mesh>
            <mesh>
                <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
                <meshBasicMaterial ref={material1} attach="material" map={texture1} side={THREE.BackSide} transparent/>
            </mesh>
            <mesh>
                <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
                <meshBasicMaterial ref={material2} attach="material" map={texture2} side={THREE.BackSide} transparent/>
            </mesh>
            <mesh>
                <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
                <meshBasicMaterial ref={material3} attach="material" map={texture3} side={THREE.BackSide} transparent/>
            </mesh>
        </>
    )
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" value={Math.round(props.value)} size={75} sx={{color: "#ffffff"}} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="#ffffff" fontSize={15} fontWeight={700}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

function Loader() {
    const { progress } = useProgress()
    return <Html center>
        <div className="h-[100vh] w-[100vw] flex flex-col justify-center items-center" style={{backgroundColor: "rgb(189 119 98 / 0.8)"}}>
            <CircularProgressWithLabel value={progress} />
        </div>
    </Html>
}

export default function DayNight({setScene}) {

    const [render, setRender] = useState(0)
    const [update, setUpdate] = useState(0)
    function Foo() {
        const camera = useThree((state) => state.camera)
        const controls = useThree((state) => state.controls)
        if(render === 0) {
            camera.position.set(0.014484386705807473 ,  0.00510210952031023 ,  -0.09881381998586808);
        }
        // Get current camera position to simply set the view
        /*useEffect(() => {
            console.log(camera.position.x, ', ', camera.position.y, ', ', camera.position.z)
        }, [update])*/
    }

    const { isLandscape } = useMobileOrientation()
    return (
        <Suspense fallback={<Loader/>}>
            <Canvas className="z-30" camera={{position: [0, 0, 0.1]}} legacy={false} flat={true}>
                <Foo/>
                <Controls enableZoom={false} enablePan={false} autoRotate autoRotateSpeed={0.3} enableDamping dampingFactor={0.1} rotateSpeed={-0.2}/>
                <Suspense fallback={<Loader/>}>
                    <mesh position={[-30, 40, 300]}>
                        <boxGeometry attach="geometry" args={[0, 0, 0]} />
                        <meshBasicMaterial />
                        <Html center>
                            <div className="w-[150px] h-[150px]">
                                <img src="/images/assets/icona-duomo.svg" className="icona-duomo" alt="hotspot-duomo"/>
                            </div>
                        </Html>
                    </mesh>
                    <Scene/>
                </Suspense>
            </Canvas>
            <MainMenu which={0}/>
            <div className="p-[10px] lg:p-[30px] absolute top-0 left-0">
                <img src="/images/Illumi-logo-white.png" className={`${isMobile ? "h-[50px] w-[100%] max-w-[157px]" : "h-[83px] w-[100%]"}`}/>
            </div>
            <div className="absolute bottom-0 flex flex-row gap-[50px] pb-[10px] lg:pb-[30px] z-40" style={{width: isMobile && !isLandscape ? 270 : 370, marginLeft: isMobile && isLandscape ? 20 : 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center', marginBottom: isMobile && !isLandscape ? '40px' : 0}}>
                <button className="flex flex-col items-center" onClick={() => setScene(11)}>
                    <img src="/images/assets/arrow-up.svg" className="w-[50px] h-[50px] mb-2" style={{display: isMobile ? "none" : "block"}}/>
                    <div>
                        <span className={`font-primary font-extrabold text-white whitespace-nowrap ${isMobile ? 'text-[16px]' : 'text-[27px]'}`} style={{textShadow: "1px 1px #000000"}}>vai al rooftop</span>
                    </div>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    setScene(2)
                }}>
                    <img src="/images/assets/arrow-down.svg" className="w-[50px] h-[50px] mb-2" style={{display: isMobile ? "none" : "block"}}/>
                    <div>
                        <span className={`font-primary font-extrabold text-white whitespace-nowrap ${isMobile ? 'text-[16px]' : 'text-[27px]'}`} style={{textShadow: "1px 1px #000000"}}>vai all'attico</span>
                    </div>
                </button>
            </div>
            <a href="https://spazioillumi.it/" rel="noreferrer" target="_blank" className={`absolute bottom-0 right-0 font-primary text-white font-bold self-end m-[10px] lg:m-[30px] z-40 ${isMobile && !isLandscape ? "ml-[auto] mr-[auto] text-center left-0" : ""}`}>spazioillumi.it</a>
            <div
            dangerouslySetInnerHTML={{__html: `
            <video id="day-night-video" src="/images/panoramas/daynight-video.mp4" type='video/mp4' controls autoplay playsinline muted loop>
            </video>
            `}}
            />
        </Suspense>
    )
}
