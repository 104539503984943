import React from 'react'
import {isMobile, useMobileOrientation} from "react-device-detect";
import {Slide} from "react-slideshow-image";

const slideImages = [
    {
        url: '/images/popups/domotica/1-multimediale.jpg',
    },
    {
        url: '/images/popups/domotica/2-multimediale.jpg',
    },
    {
        url: '/images/popups/domotica/3-multimediale.jpg',
    },
    {
        url: '/images/popups/domotica/4-multimediale.jpg',
    }
];

const Slideshow = () => {
    const properties = {
        duration: 4000,
        autoplay: true,
        transitionDuration: 1000,
        arrows: true,
        infinite: true,
        easing: "ease",
        prevArrow: <button style={{marginLeft: 20}} ><img alt="prev-slide" src="/images/assets/slider-arrow-prev.png" className="h-[45px] w-[45px] cursor-pointer" /></button>,
        nextArrow: <button style={{marginRight: 20}}><img alt="prev-slide" src="/images/assets/slider-arrow-next.png" className="h-[45px] w-[45px] cursor-pointer" /></button>,
        cssClass: ''
    };
    return (
        <div className="slide-container h-full">
            <Slide {...properties}>
                {slideImages.map((slideImage, index) => (
                    <div className="each-slide h-full" key={index}>
                        <div className="bg-cover bg-no-repeat h-full w-full" style={{backgroundImage: `url(${slideImage.url})`}}>
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default function DomoticaHotspot({setHotspot}) {
    const { isLandscape } = useMobileOrientation()
    return (
        <div className="bg-[#bd7762]/80 h-screen w-screen absolute top-0 left-0 z-40 flex flex-col items-center justify-center">
            <div className={`overflow-hidden bg-[#f2efef] flex ${isMobile && !isLandscape ? "flex-col" : isMobile && isLandscape} ${isMobile ? "w-[90vw] h-[90vh]" : "flex-row w-[80vw] h-[80vh]"}`}>
                <div className={`w-full overflow-hidden ${isMobile && !isLandscape ? "h-[400px]" :  "h-full"} ${isMobile && isLandscape ? "min-w-[300px]" : ""}`}>
                    <Slideshow />
                </div>
                <div className={`overflow-hidden flex flex-col ${isMobile ? "h-full" : "w-[55%] min-w-[55%]"}`}>
                    <div className={`p-[30px] pb-[10px] ${isMobile && !isLandscape ? "absolute top-[30px] right-[10px] p-[20px]" : isMobile && isLandscape ? "absolute top-[10px] right-[30px] p-[20px]" : "self-end"}`}>
                        <img alt="close-icon" src="/images/assets/close-menu-icon.svg" className="h-[45px] w-[45px] cursor-pointer" onClick={() => setHotspot(0)}/>
                    </div>
                    <div className={`overflow-hidden ${isMobile ? "p-[30px] pt-[40px]" : "p-[60px] pt-[0]"}`} style={{overflowY: 'auto'}}>
                        <h2 className="font-primary text-[38px] leading-[50px] font-semibold pb-10 pt-[30px]">Domotica e dotazione multimediale</h2>
                        <p className="font-primary text-[13px] leading-[21px] pb-[30px]">Il sistema progettato secondo il protocollo knx, unico standard mondiale approvato e riconosciuto per la building automation, permette di controllare gestire contemporaneamente tutti gli impianti della location.</p>
                        <h3 className="font-primary text-[20px] leading-[30px] font-bold pb-2">Elenco dotazione multimediale</h3>
                        <p className="font-primary text-[13px] leading-[21px] pb-[30px]">
                            • Sistema wi-fi in fibra ottica da 1 giga a copertura totale<br/>
                            • 12 postazioni di rete che permettono i collegamenti dei pc alla rete<br/>
                            • La presa hdmi presente nella sala permette il collegamento diretto del video da un pc ai monitor<br/>
                            • Una matrice video che offre la possibilita’ di collegare fino a quattro sorgenti e distribuirle sui tre monitor<br/>
                            • Due monitor professionali sony da 77”<br/>
                            • Un monito professionale sony da 49”<br/>
                            • Un telo da proiezione motorizzato l 5 m x h 3 m<br/>
                            • Due microfoni ad archetto<br/>
                            • Due microfoni a gelato<br/>
                            • Sistema audio yamaha con casse da incasso per distribuire audio e per presentazioni<br/>
                            • Trave luminosa<br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
