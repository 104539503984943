import React from 'react'
import NavigationArrow from "../components/NavigationArrow";

export default function Scene6Mesh({setScene, setRender}) {
    return (
        <>
            <NavigationArrow setScene={setScene} sceneToSet={3} setRender={setRender} position={[-50, -40, 10]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(30deg'} classes="external-nav-arrow"/>
        </>
    )
}
