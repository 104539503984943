import React from 'react'
import {isMobile, useMobileOrientation} from "react-device-detect";
import {Slide} from "react-slideshow-image";

const slideImages = [
    {
        url: '/images/popups/green/1-green.jpg',
    },
    {
        url: '/images/popups/green/2-green.jpg',
    },
    {
        url: '/images/popups/green/3-green.jpg',
    },
    {
        url: '/images/popups/green/4-green.jpg',
    }
];

const Slideshow = () => {
    const properties = {
        duration: 4000,
        autoplay: true,
        transitionDuration: 1000,
        arrows: true,
        infinite: true,
        easing: "ease",
        prevArrow: <button style={{marginLeft: 20}} ><img alt="prev-slide" src="/images/assets/slider-arrow-prev.png" className="h-[45px] w-[45px] cursor-pointer" /></button>,
        nextArrow: <button style={{marginRight: 20}}><img alt="prev-slide" src="/images/assets/slider-arrow-next.png" className="h-[45px] w-[45px] cursor-pointer" /></button>,
        cssClass: ''
    };
    return (
        <div className="slide-container h-full">
            <Slide {...properties}>
                {slideImages.map((slideImage, index)=> (
                    <div className="h-full">
                        <div key={index} className="bg-cover h-full p-12" style={{backgroundImage: `url(${slideImage.url})`}}>
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default function GreenHotspot({setHotspot}) {
    const { isLandscape } = useMobileOrientation()
    return (
        <div className="bg-[#bd7762]/80 h-screen w-screen absolute top-0 left-0 z-40 flex flex-col items-center justify-center">
            <div className={`overflow-hidden bg-[#f2efef] flex ${isMobile && !isLandscape ? "flex-col" : isMobile && isLandscape} ${isMobile ? "w-[90vw] h-[90vh]" : "flex-row w-[80vw] h-[80vh]"}`}>
                <div className={`relative w-full overflow-hidden ${isMobile && !isLandscape ? "h-full" :  "h-full"} ${isMobile && isLandscape ? "min-w-[300px]" : ""}`}>
                    <div className="flex flex-row justify-between items-start absolute w-full">
                        <div className={`z-40 py-10 w-full ${isLandscape ? "px-[100px]" : "px-[30px]"}`}>
                            <h2 className="font-primary text-[45px] font-semibold text-white drop-shadow-xl">Green</h2>
                            <p className="font-primary text-[17px] font-bold leading-[22px] text-white drop-shadow-xl">Il verde, protagonista predominante della location,<br/>
                                è presente in tutte le sue forme.</p>
                        </div>
                        <div className={`absolute p-[20px] pb-[10px] top-[10px] right-[10px] z-[50] ${isMobile && !isLandscape ? "top-[10px] right-[10px] p-[0px]" : isMobile && isLandscape ? "top-[10px] right-[10px] p-[0px]" : ""}`}>
                            <img alt="close-icon" src="/images/assets/close-menu-icon.svg" className="h-[45px] w-[45px] min-h-[45px] min-w-[45px] cursor-pointer" onClick={() => setHotspot(0)}/>
                        </div>
                    </div>
                    <Slideshow />
                </div>
            </div>
        </div>
    )
}
