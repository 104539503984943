import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import ChangeAllestimento from "../components/ChangeAllestimento";
import Hotspot from "../components/Hotspot";

export default function Scene9Mesh({setScene, setRender, setHotspot}) {
    return (
        <>
            <Hotspot position={[50, 0, -2]} setOpen={setHotspot} hotspot={5} image="/images/assets/hotspot/hotspot-guardaroba.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={8} setRender={setRender} position={[20, -35, 80]} rotation={'rotateZ(0deg) rotateY(-10deg) rotateX(-50deg'}/>
            <ChangeAllestimento setScene={setScene} sceneToSet={10} setRender={setRender} position={[20, -5, 20]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(0deg'} />
        </>
    )
}
