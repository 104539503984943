import React from 'react'
import {Html} from "@react-three/drei";
import {isMobile} from "react-device-detect";

export default function NavigationArrow({setScene, sceneToSet, setRender, position, rotation, classes}) {
    //const size = isMobile ? 50 : 100
    const size = 100

    const handleNavigation = () => {
        setRender(0)
        setScene(sceneToSet)
    }

    return (
        <mesh position={position}>
            <boxGeometry attach="geometry" args={[0, 0, 0]} />
            <meshBasicMaterial/>
            <Html center>
                <div className={isMobile ? `w-[75px] h-[75px]` : `w-[100px] h-[100px]`} style={{transform: rotation}}>
                    <img alt="arrow-navigation-2" src="/images/assets/freccia-nav.svg" className={`cursor-pointer ${classes}`} onClick={handleNavigation}/>
                </div>
            </Html>
        </mesh>
    )
}
