import React from 'react'
import MainMenu from "../components/MainMenu";

export default function DayNightContent({setScene}) {
    return (
        <>
            <MainMenu/>
            <div className="p-[30px] absolute top-0 left-0">
                <img src="/images/Illumi-logo-white.png" className="h-[83px] w-[100%] max-w-[50px]"/>
            </div>
            <div className="absolute bottom-0 flex flex-row gap-[50px] pb-[30px]" style={{width: 450, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center'}}>
                <button className="flex flex-col items-center" onClick={() => setScene(11)}>
                    <img src="/images/assets/arrow-up.svg" className="w-[45px] h-[45px] lg:w-[50px] lg:h-[50px] mb-2"/>
                    <div>
                        <span className="font-primary font-extrabold text-[27px] text-white" style={{textShadow: "1px 1px #000000"}}>vai al rooftop</span>
                    </div>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    setScene(2)
                }}>
                    <img src="/images/assets/arrow-down.svg" className="w-[45px] h-[45px] lg:w-[50px] lg:h-[50px] mb-2"/>
                    <div>
                        <span className="font-primary font-extrabold text-[27px] text-white" style={{textShadow: "1px 1px #000000"}}>vai all'attico</span>
                    </div>
                </button>
            </div>
            <a href="https://spazioillumi.it/" rel="nofollow" target="_blank" className="absolute bottom-0 right-0 font-primary text-white font-bold self-end p-[30px]">spazioillumi.it</a>
            <div
            dangerouslySetInnerHTML={{__html: `
            <video id="day-night-video" src="/images/panoramas/IMG_20220606_201435_00_merged-1.mp4" type='video/mp4' controls autoplay playsinline muted loop>
            </video>
            `}}
            />
        </>
    )
}
