import React from 'react'
import {Html} from "@react-three/drei";

export default function Hotspot({position, setOpen, hotspot, image}) {
    return (
        <mesh position={position}>
            <boxGeometry attach="geometry" args={[0, 0, 0]} />
            <meshBasicMaterial />
            <Html center>
                <div className="w-[150px] h-[150px]">
                    <img src={image} className="cursor-pointer" onClick={() => setOpen(hotspot)} alt="hotspot"/>
                </div>
            </Html>
        </mesh>
    )
}
