import '../src/App.css'
import LandingPage from "./threejs/LandingPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import VirtualTour from "./threejs/VirtualTour";
import VirtualTour2 from "./threejs/VirtualTour2";

export default function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<LandingPage />}/>
              <Route path="/tour" element={<VirtualTour2 />} />
          </Routes>
      </BrowserRouter>
  );
}
