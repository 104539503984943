import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import Hotspot from "../components/Hotspot";

export default function Scene4Mesh({setScene, setRender, setHotspot}) {
    return (
        <>
            <Hotspot position={[100, -15, -25]} setOpen={setHotspot} hotspot={9} image="/images/assets/hotspot/hotspot-cucina.png"/>
            <Hotspot position={[100, -20, 60]} setOpen={setHotspot} hotspot={10} image="/images/assets/hotspot/hotspot-show-cooking.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={5} setRender={setRender} position={[-20, -22, 20]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-30deg'}/>
            <NavigationArrow setScene={setScene} sceneToSet={3} setRender={setRender} position={[-20, -25, -50]} rotation={'rotateZ(5deg) rotateY(5deg) rotateX(-50deg'}/>
        </>
    )
}
