import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import {Fade} from "react-slideshow-image";
import {useNavigate} from "react-router-dom";
import { isMobile, useMobileOrientation } from "react-device-detect";
import '../index.css'

const slideImages = [
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-ingresso.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-show-cooking.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-rooftop.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-rooftop1.jpg',
    },
    {
        url: '/images/landing-slider/spazio-eventi-milano-illumi-allestimento.jpg',
    }
];

const Slideshow = () => {
    const properties = {
        duration: 3000,
        autoplay: true,
        transitionDuration: 1000,
        arrows: false,
        infinite: true,
        easing: "ease",
        cssClass: 'mb-[-1px]'
    };
    return (
        <div className="slide-container h-full">
            <Fade {...properties} >
                {slideImages.map((slideImage, index)=> (
                    <div className="each-slide h-full" key={index}>
                        <div className="bg-cover bg-no-repeat bg-center h-full w-full p-10 flex flex-col justify-between" style={{backgroundImage: `url(${slideImage.url})`}}>
                            <span />
                            <a href="https://spazioillumi.it/" rel="nofollow" target="_blank" className="font-primary text-white font-bold self-end pb-[50px] lg:pb-[0px]" style={{writingMode: isMobile ? "vertical-rl" : "", marginRight: isMobile ? -20 : ""}}>spazioillumi.it</a>
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    )
}

const LandingPage = () => {
    let navigate = useNavigate();
    const { isLandscape } = useMobileOrientation()
    if(isMobile) {
        return (
            <div className={`w-screen h-screen flex ${isLandscape ? 'flex-row' : 'flex-col'}`}>
                <div className={`flex flex-col h-full justify-between bg-[#f2efef] min-w-[375px] px-8 ${isLandscape ? "py-4" : "py-8"}`}>
                    <section className={`flex flex-col ${isLandscape ? "pb-4" : "pb-12"}`}>
                        {/*<span className="font-primary text-base font-bold self-end cursor-pointer">eng</span>*/}
                        <img src="/images/Illumi-Logo.png" className={`${isMobile ? "h-[40px] w-[100%] max-w-[157px]" : "h-[83px] w-[100%]"}`} style={{marginTop: isLandscape ? -10 : 0, marginBottom: isLandscape ? 0 : -30}}/>
                    </section>
                    <section>
                        <h2 className="font-primary text-[20px] leading-[27px] uppercase font-black pb-3">SCOPRI LA NOSTRA VIRTUAl experience</h2>
                        <p className="font-primary text-[15px] leading-[22px] pb-[20px]">Uno spazio penthouse a due passi dal Duomo per eventi d’affari boutique</p>
                        <button className="flex flex-row" onClick={() => navigate(`/tour`)}>
                            <div className="w-[30px] h-[30px] border-2 border-bronze rounded-full flex flex-col items-center justify-center mr-[17px]"><FontAwesomeIcon style={{fontSize: 12}} className="text-bronze" icon={faAngleRight} /></div>
                            <div>
                                <span className="font-primary text-xl">inizia</span>
                                <div className="w-full h-[2px] bg-bronze"/>
                            </div>
                        </button>
                    </section>
                    <section className="flex flex-col items-center" style={{height: isLandscape ? 50 : 0}}>
                    </section>
                </div>
                <div className="h-full overflow-hidden">
                    <Slideshow />
                </div>
            </div>
        )
    } else {
        return (
            <div className="w-screen h-screen flex flex-row">
                <div className="flex flex-col justify-between w-[687px] min-w-[687px] bg-[#f2efef] px-16 py-8">
                    <section className="flex flex-col">
                        {/*<span className="font-primary text-base font-bold self-end cursor-pointer">eng</span>*/}
                        <img src="/images/Illumi-Logo.png" className="h-[65px] w-[267px]"/>
                    </section>
                    <section>
                        <h2 className="font-primary text-[45px] leading-[54px] uppercase font-black pb-4">SCOPRI LA NOSTRA VIRTUAl experience</h2>
                        <p className="font-primary text-xl pb-[70px]">Uno spazio penthouse a due passi dal Duomo <br/> per eventi d’affari boutique</p>
                        <button className="flex flex-row" onClick={() => navigate(`/tour`)}>
                            <div className="w-[30px] h-[30px] border-2 border-bronze rounded-full flex flex-col items-center justify-center mr-[17px]"><FontAwesomeIcon style={{fontSize: 12}} className="text-bronze" icon={faAngleRight} /></div>
                            <div>
                                <span className="font-primary text-xl">inizia</span>
                                <div className="w-full h-[2px] bg-bronze"/>
                            </div>
                        </button>
                    </section>
                    <section className="flex flex-col items-center">
                    </section>
                </div>
                <div className="h-full w-full overflow-hidden">
                    <Slideshow />
                </div>
            </div>
        )
    }
}

export default LandingPage;