import ReactDOM from 'react-dom'
import React, {Suspense, useRef, useState} from 'react'
import Scene1 from "./scenes/Scene1";
import Scene2 from "./scenes/Scene2";
import Scene3 from "./scenes/Scene3";
import Scene7 from "./scenes/Scene7";
import Scene8 from "./scenes/Scene8";
import Scene9 from "./scenes/Scene9";
import Scene10 from "./scenes/Scene10";
import Scene11 from "./scenes/Scene11";
import Scene12 from "./scenes/Scene12";
import Scene4 from "./scenes/Scene4";
import Scene5 from "./scenes/Scene5";
import Scene6 from "./scenes/Scene6";
import LandingPage from "./LandingPage";
import DayNight from "./scenes/DayNight";

export default function VirtualTour() {

    const [isLoading, setIsLoading] = useState(false)
    const [currentScene, setCurrentScene] = useState(13)

    return (
        <>
            {currentScene === 0 && <LandingPage />}
            {currentScene === 1 && <Scene1 setScene={setCurrentScene}/>}
            {currentScene === 2 && <Scene2 setScene={setCurrentScene}/>}
            {currentScene === 3 && <Scene3 setScene={setCurrentScene}/>}
            {currentScene === 4 && <Scene4 setScene={setCurrentScene}/>}
            {currentScene === 5 && <Scene5 setScene={setCurrentScene}/>}
            {currentScene === 6 && <Scene6 setScene={setCurrentScene}/>}
            {currentScene === 7 && <Scene7 setScene={setCurrentScene}/>}
            {currentScene === 8 && <Scene8 setScene={setCurrentScene}/>}
            {currentScene === 9 && <Scene9 setScene={setCurrentScene}/>}
            {currentScene === 10 && <Scene10 setScene={setCurrentScene}/>}
            {currentScene === 11 && <Scene11 setScene={setCurrentScene}/>}
            {currentScene === 12 && <Scene12 setScene={setCurrentScene}/>}
            {currentScene === 13 && <DayNight setScene={setCurrentScene} setLoading={setIsLoading}/>}
        </>
    )
}

ReactDOM.render(<VirtualTour/>, document.getElementById('root'))
