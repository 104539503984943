import React from 'react'
import NavigationArrow from "../components/NavigationArrow";
import Hotspot from "../components/Hotspot";

export default function Scene3Mesh({setScene, setRender, setHotspot}) {
    return (
        <>
            <Hotspot position={[-100, -15,20]} setOpen={setHotspot} hotspot={7} image="/images/assets/hotspot/hotspot-sanificazione.png"/>
            <NavigationArrow setScene={setScene} sceneToSet={4} setRender={setRender} position={[0, -35, -60]} rotation={'rotateZ(0deg) rotateY(0deg) rotateX(-50deg'}/>
            <NavigationArrow setScene={setScene} sceneToSet={6} setRender={setRender} position={[-45, -35, 85]} rotation={'rotateZ(-5deg) rotateY(-10deg) rotateX(-50deg'} classes={"external-nav-arrow"}/>
            <NavigationArrow setScene={setScene} sceneToSet={2} setRender={setRender} position={[-70, -25, -5]} rotation={'rotateZ(0deg) rotateY(-10deg) rotateX(-50deg'}/>
        </>
    )
}
