import * as THREE from 'three'
import React, {Suspense, useRef} from 'react'
import {Canvas, extend, useFrame, useLoader, useThree} from 'react-three-fiber'
import NavigationArrow from "../components/NavigationArrow";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import MainMenu from "../components/MainMenu";

extend({OrbitControls})

function Controls(props) {
    const {camera, gl} = useThree()
    const ref = useRef()
    useFrame(() => ref.current.update())
    return <orbitControls ref={ref} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]}/>
}

function Scene() {
    const texture = useLoader(THREE.TextureLoader, '/images/panoramas/panorama-11.jpg')
    return (
        <mesh receiveShadow>
            <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
            <meshBasicMaterial attach="material" map={texture} side={THREE.BackSide}/>
        </mesh>
    )
}

export default function Scene11({setScene}) {
    return (
        <>
            <Canvas camera={{fov: 90, position: [0, 0, 0.1]}}>
                <Controls enableZoom={false} enablePan={false} enableDamping dampingFactor={0.1} rotateSpeed={-0.2}/>
                <Suspense fallback={null}>
                    <NavigationArrow setScene={setScene} sceneToSet={12} position={[-45, -35, 70]} rotation={'rotateZ(0deg) rotateY(-10deg) rotateX(-70deg'}/>
                    <Scene/>
                </Suspense>
            </Canvas>
            <MainMenu/>
            <div className="p-[30px] absolute top-0 left-0">
                <img src="/images/Illumi-logo-white.png" className="h-[83px] w-[100%]"/>
            </div>
            <div className="absolute bottom-0 flex flex-row gap-[50px] pb-[30px]" style={{width: 200, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center'}}>
                <button className="flex flex-col items-center w-[200px]" onClick={() => setScene(1)}>
                    <img src="/images/assets/arrow-down.svg" className="w-[50px] h-[50px] mb-2"/>
                    <div>
                        <span className="font-primary font-extrabold text-[27px] text-white" style={{textShadow: "1px 1px #000000"}}>vai all'attico</span>
                    </div>
                </button>
            </div>
            <a href="https://spazioillumi.it/" rel="nofollow" target="_blank" className="absolute bottom-0 right-0 font-primary text-white font-bold self-end p-[30px]">spazioilumi.it</a>
        </>
    )
}
